<template>
  <NotFound> </NotFound>
</template>

<script>
import NotFound from "@/components/layouts/NotFound";

export default {
  name: "Not Found",
  components: {
    NotFound
  },
  data: () => ({}),
  methods: {},
  computed: {},

  created() {}
};
</script>

<style scoped></style>
